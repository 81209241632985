import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    tablaModal: [],
  },
  getters: {
    getField,
    getIDItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return null;
      }
      return ITEM.id;
    },
    getCamaItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.cama_nombre;
    },
    getPisoSalaItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.piso_sala_nombre;
    },
    getImporteXDiaSalaCama(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return 0;
      }
      return ITEM.importe_x_dia;
    },
  },
  mutations: {
    updateField,
    setTablaModal: (state, data) => { state.tablaModal = data; },
    cargarModalPieza(state, data) {
      const TEMP_ARRAY = [];
      data.forEach((el) => {
        const ITEM = {
          cama_nombre: el.cama_nombre,
          clase_nombre: el.clase_nombre,
          estado: el.estado,
          fecha: el.fecha,
          id: el.id,
          medico: el.medico,
          numero: el.numero,
          piso_sala_nombre: el.piso_sala_nombre,
          tratamiento: el.tratamiento,
          checkInput: false,
          importe_x_dia: el.importe_x_dia,
        };
        TEMP_ARRAY.push(ITEM);
      });
      state.tablaModal = TEMP_ARRAY;
    },
    cargarSalaCamaID(state, data) {
      if (data !== null) {
        const INDEX = state.tablaModal.findIndex((el) => el.id === data);
        state.tablaModal[INDEX].checkInput = true;
      }
    },
  },
  actions: {
    resetModalPieza({ commit }) {
      commit('setTablaModal', []);
    },
  },
};
